.infoContainer {
  max-width: 880px;
  width: 90%;
  margin: 0 auto;
  padding: 80px 0;
}

.info {
  color: var(--Gray-500);
}
