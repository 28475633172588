.elipse {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: radial-gradient(circle, var(--Green-500) 0%, transparent 100%);
  position: absolute;
  transform: translateX(-50%);
  opacity: 0.3;
  filter: blur(300px);
  z-index: -1;
}
