.text {
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  font-size: 14px;
  color: var(--Base-0);
}
.base950 {
  color: var(--Base-950);
}
.base800 {
  color: var(--Base-800);
}
.base200 {
  color: var(--Base-200);
}
.base500 {
  color: var(--Base-500);
}
.base300 {
  color: var(--Base-300);
}
.xs {
  font-size: 12px;
}
.sm {
  font-size: 14px;
}
.md {
  font-size: 14px;
}
.base {
  font-size: 16px;
}
.lg {
  font-size: 18px;
}
.xl {
  font-size: 20px;
}
.xl2 {
  font-size: 24px;
}
.xl3 {
  font-size: 30px;
}
.xl4 {
  font-size: 36px;
}
.xl6 {
  font-size: 40px;
}

.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.bold {
  font-weight: 700;
}
.semiBold {
  font-weight: 500;
}
.font600 {
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .xl6 {
    font-size: 32px;
  }
  .xl4 {
    font-size: 28px;
  }
  .xl3 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 520px) {
  .xl3 {
    font-size: 20px;
  }
  .xl6 {
    font-size: 20px;
  }
}
