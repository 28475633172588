.wrapper {
}
.container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 80px 0;
}
.services {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
.service {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  border-radius: 24px;
  background: var(--Base-50);
  padding: 48px;
  box-shadow: var(0px) var(1px) var(2px) var(0px) var(rgba(0, 0, 0, 0.04));
  gap: 48px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
.uppArrow {
  transform: translateY(2px);
}
.button {
  color: var(--Base-700);
  padding: 0;
  margin-right: auto;
}

.info {
  grid-row: span 2;
}
@media only screen and (max-width: 1199px) {
  .service {
    padding: 32px;
    grid-template-columns: 1fr;
    gap: 32px;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    gap: 40px;
  }
  .service {
    padding: 20px;

    gap: 20px;
  }
}
