.heading {
  font-size: 60px;
  color: var(--Base-0);

  font-style: normal;

  font-weight: 600;
  line-height: 100%;
}
.base950 {
  color: var(--Base-950);
}
.highlight {
  color: var(--Green-500);
}
.textCenter {
  text-align: center;
}
@media only screen and (max-width: 1199px) {
  .heading {
    font-size: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .heading {
    font-size: 36px;
  }
}
@media only screen and (max-width: 520px) {
  .heading {
    font-size: 30px;
  }
}
