.button {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.333%;
  letter-spacing: -0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  white-space: nowrap;
  background-image: url(../../../images/blue_button.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leftRadius {
  background-image: url(../../../images/leftRadius.png);
}

.transparent {
  background: transparent;
  color: var(--Base-0);
  font-weight: 400;
}

.wFull {
  width: 100%;
}

.spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
  margin-right: 5px;
  /* Add some space between the spinner and the text */
}

.loading {
  gap: 0px;
  opacity: 0.8;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 520px) {
  .button {
    font-size: 14px;
    padding: 10px 16px;
  }
}