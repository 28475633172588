.wrapper {
  padding: 192px 0;

  position: relative;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: flex-start;
}
.aboutCeo {
  display: flex;
  flex-direction: column;
  gap: 64px;
  position: sticky;
  position: -webkit-sticky;
  top: -70px;
}

.card {
  background: linear-gradient(
    to bottom right,
    rgb(45, 213, 90, 0.1) 0%,
    rgb(45, 213, 90, 0.15) 0.01%,
    black 40%,
    black 60%,
    rgb(45, 213, 90, 0.15) 99.99%,
    rgb(45, 213, 90, 0.1) 100%
  );
  border-radius: 16px;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  max-width: 412px;
  width: 100%;
}
.img {
  width: 100%;
  border-radius: 16px;
}
.ceoInfo {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0 12px;
  padding: 12px;
}
.name {
  grid-column: 1/-1;
}
.linkedin {
  color: var(--Green-500);
  font-size: 20px;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 128px;
}
.hr {
  height: 1px;
  background: none;
  border: 1px solid var(--Base-800);
  opacity: 0.5;
}
.details {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.logo {
  margin-left: auto;
  max-width: 44px;
  width: 100%;
}
.button {
  color: var(--Green-500);
  margin-right: auto;
  padding: 0;
}

@media only screen and (max-width: 1199px) {
  .container {
    gap: 60px;
  }
  .infoContainer {
    gap: 64px;
  }
}

@media only screen and (max-width: 991px) {
  .container {
    grid-template-columns: 1fr;
    gap: 64px;
  }

  .infoContainer {
    gap: 64px;
  }
  .aboutCeo {
    gap: 32px;
    position: static;
  }
  .details {
    gap: 24px;
  }
  .position {
    font-size: 16px;
  }
  .name {
    font-size: 20px;
  }
  .info {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    padding: 80px 0;
  }
  .logo {
    max-width: 36px;
  }
}
