.wrapper {
  padding: 80px 0;
}

.container {
  display: flex;
  flex-direction: column;

  gap: 20px;
  background: url(../../../images/pattern.png),
    linear-gradient(to bottom right,
      rgba(1, 87, 254, 0.1) 0%,
      rgba(1, 87, 254, 0.15) 0.01%,
      black 40%,
      black 60%,
      rgba(1, 87, 254, 0.15) 99.99%,
      rgba(1, 87, 254, 0.1) 100%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  padding: 80px;
}

.sectionTitle {
  border-radius: 24px;
  background: rgba(45, 213, 90, 0.08);
  padding: 4px 16px;
  color: var(--Green-500);
  margin-right: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
}

.heading {
  max-width: 500px;
  width: 100%;
}

.info {
  max-width: 412px;
  width: 100%;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding-top: 48px;
}

.card {
  padding: 24px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: var(--Base-950);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 20px 16px;
}

.icon {
  max-width: 64px;
  width: 100%;
}

.title {
  line-height: 133.33%;
}

.details {
  grid-column: 1/-1;
}

@media only screen and (max-width: 1199px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .info,
  .heading {
    max-width: 900px;
  }

  .cards {
    grid-template-columns: 1fr 1fr;
    padding-top: 32px;
  }
}

@media only screen and (max-width: 991px) {
  .wrapper {
    background: url(../../../images/pattern.png),
      linear-gradient(to bottom right,
        rgba(1, 87, 254, 0.1) 0%,
        rgba(1, 87, 254, 0.15) 0.01%,
        black 40%,
        black 60%,
        rgba(1, 87, 254, 0.15) 99.99%,
        rgba(1, 87, 254, 0.1) 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .container {
    padding: 0px;
    border-radius: 0;

    background: transparent;
  }
}

@media only screen and (max-width: 767px) {
  .cards {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 520px) {
  .info {
    font-size: 18px;
  }
}