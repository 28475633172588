.wrapper {
  padding: 80px 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: url(../../../images/pattern.png),
    linear-gradient(to bottom right,
      rgba(1, 87, 254, 0.1) 0%,
      rgba(1, 87, 254, 0.15) 0.01%,
      black 40%,
      black 60%,
      rgba(1, 87, 254, 0.15) 99.99%,
      rgba(1, 87, 254, 0.1) 100%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  padding: 0 80px;
}

.infoWrapper {
  padding: 80px 0;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 48px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 610px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 0px;
  flex-wrap: wrap;
  padding: 16px 0;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.companyLogo {
  max-width: 270px;
  width: 100%;
  margin-left: auto;
}

.bottomBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  padding: 24px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.socialContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.socialLink {
  color: var(--Base-0);
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .companyLogo {
    max-width: 220px;
  }
}

@media only screen and (max-width: 991px) {
  .wrapper {
    background: url(../../../images/pattern.png),
      linear-gradient(to bottom right,
        rgba(1, 87, 254, 0.1) 0%,
        rgba(1, 87, 254, 0.15) 0.01%,
        black 40%,
        black 60%,
        rgba(1, 87, 254, 0.15) 99.99%,
        rgba(1, 87, 254, 0.1) 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
  }

  .container {
    border-radius: 0;

    background: transparent;
  }

  .infoWrapper {
    grid-template-columns: 1fr;
  }

  .companyLogo {
    margin: 0 auto;
  }

  .infoWrapper {
    padding: 48px 0;
  }

  .container {
    padding: 0px;
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .bottomBar {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 0;
  }

  .infoWrapper {
    padding: 32px 0;
    padding-top: 24px;
    gap: 32px;
  }

  .container {
    padding-top: 20px;
  }

  .info,
  .copyRight {
    font-size: 16px;
  }

  .socialLink {
    font-size: 14px;
  }

  .companyLogo {
    max-width: 158px;
    margin: 0 auto;
  }

  .copyRight {
    text-align: center;
  }
}