@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
}

:root {
  --Base-0: #fff;
  --Base-50: #f7f7f7;
  --Base-950: #000;
  --Base-800: #333;
  /* --Green-500: #2dd55a;
  --Green-700: #0dab37; */
  --Green-500: #0157FE;
  --Green-700: #0157FE;
  --Base-700: #474747;
  --Base-200: #e7e7e7;
  --Base-300: #c9c9c9;
  --Base-500: #797979;
}

#root {
  min-height: 100vh;
}

body {
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: var(--Base-950);
}

.container {
  max-width: 1199px;
  width: 90%;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

input {
  border: none;
  outline: none;
  background: transparent;
}

button,
a {
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;
}

button:hover,
a:hover {
  opacity: 0.8;
  transform: translateY(-3px);
}

button:active {
  transform: translateY(0px);
}

.overflow {
  scrollbar-width: thin;
  scrollbar-color: var(--Primary-Default) rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari browsers */
.overflow::-webkit-scrollbar {
  width: 3px;
  /* Width of vertical scrollbar */
  height: 2px;
  /* Height of horizontal scrollbar */
  border-radius: 5px;
}

.overflow::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(250, 250, 250, 0.2);
  /* Ensure background is set */
}

.overflow::-webkit-scrollbar-thumb {
  background-color: var(--Primary-Default);
  border-radius: 5px;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.highlight {
  color: var(--Primary-Default);
}

input::placeholder {
  color: var(--Text-Placeholder);
}