.wrapper {
  background: var(--Base-0);
  padding: 80px 0;
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  position: relative;
  z-index: 1;
  max-width: 904px;
}
.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 100px);
  height: 100%;
  filter: blur(100px);
  background: linear-gradient(
    to bottom right,
    var(--Base-0) 0%,
    var(--Base-0) 0.01%,
    rgba(45, 213, 90, 0.1) 40%,
    rgba(45, 213, 90, 0.1) 60%,
    var(--Base-0) 99.99%,
    var(--Base-0) 100%
  );
  z-index: -1;
}
.companyLink {
  font-weight: 700;
  color: var(--Green-700);
  text-decoration: underline;
}
.partner {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 80px 128px;
  align-items: center;
}
.even {
  grid-template-columns: auto 1fr;
}
.even .imgContainer {
  order: -1;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.imgContainer {
  border-radius: 24px;
  background: var(--Base-0);
  box-shadow: 0px 24px 48px -12px rgba(0, 0, 0, 0.06);
  padding: 10px;
  max-width: 344px;
  width: 100%;
  margin-left: auto;
}
.img {
  width: 100%;
}
.logo {
  max-width: 104px;
}
@media only screen and (max-width: 991px) {
  .partner {
    gap: 60px;
  }
  .imgContainer {
    max-width: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .partner {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .infoContainer {
    gap: 24px;
  }
  .imgContainer {
    order: -1;
    margin: auto;
  }
}

@media only screen and (max-width: 520px) {
  .container {
    gap: 48px;
  }
  .testimonial {
    font-size: 18px;
  }
  .ceo {
    font-size: 20px;
  }
  .companyName {
    font-size: 16px;
  }
}
