.wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  transition: 0.3s;
  height: 80px;
  background: var(--Base-950);
}
.wrapperBg {
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.logoContainer {
  display: flex;
}
.logo {
  max-width: 44px;
  width: 100%;
}
.navItems {
  display: flex;
  align-items: center;
  gap: 12px;
}
.navItem {
  color: var(--Base-0);

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
.icon {
  display: none;
}
.button {
  color: var(--Green-500);
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  padding: 0;
  border-radius: 6px;
  background: transparent;
  text-decoration: underline;
}

@media only screen and (max-width: 991px) {
  .navItems {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    min-height: 100vh;

    background: rgba(0, 0, 0, 0.64);
    backdrop-filter: blur(32px);
    transform: translateX(250%);
    transition: 0.3s;
    position: fixed;
    top: 100%;
    right: 0;
    padding: 32px;

    max-width: 320px;
    width: 100%;
    min-height: 100vh;
  }

  .sidebar {
    transform: translateX(0);
    right: 0;
  }
  .separator {
    display: none;
  }
  .buttonContainer {
    align-items: center;
    gap: 15px;
  }
  .button {
    font-size: 14px;
    padding: 10px 15px;
  }
  .icon {
    display: block;
    cursor: pointer;
    color: var(--Base-0);
    font-size: 28px;
  }
}
@media only screen and (max-width: 520px) {
  .logo {
    max-width: 40px;
  }
  .navItems {
    padding: 24px;
  }
}
