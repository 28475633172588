.wrapper {
  padding: 80px 0;
  padding-top: 150px;
  background: url(../../../images/pattern.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  min-height: 100vh;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px 190px;
  position: relative;
}
.headingContainer {
  grid-column: 1/-1;
}
.highlight {
  color: var(--Green-500);
}
.infoWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  justify-content: space-between;
  gap: 24px 190px;
}
.logo {
  max-width: 318px;
  width: 100%;
  margin-left: auto;
}
.infoContainer {
  max-width: 488px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 52px;
}
.buttonContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.container .elipse1 {
  top: auto;
  bottom: -100px;
  left: 0;
}
.container .elipse2 {
  top: 150px;
  right: 0;
  transform: translateX(50%);
}
.scroll {
  margin: 0px auto;
  margin-top: 80px;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 8px 20px;
  width: max-content;
  opacity: 0.5;
  border-radius: 30px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.333%;
  letter-spacing: -0.2px;

  color: var(--Base-0);

  text-align: center;
  display: flex;
  align-items: center;
  gap: 12px;
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    padding-top: 140px;
    padding-bottom: 50px;
  }
  .container {
    grid-template-columns: 1fr;
  }
  .logo {
    order: -1;
  }
  .container .heading,
  .container .secondLine {
    text-align: center;
    margin: auto;
  }

  .infoContainer {
    margin: 0 auto;
    align-items: center;
  }
  .info {
    text-align: center;
  }
  .logo {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 120px;
  }
  .logo {
    max-width: 250px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    gap: 40px;
  }

  .container,
  .infoContainer {
    gap: 24px;
  }
  .logo {
    max-width: 160px;
  }
  .info {
    font-size: 18px;
  }
}
