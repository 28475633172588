.container {
  position: relative;
  z-index: 0;
  min-height: 200vh; /* Ensure there's enough height for scrolling */
  padding-top: 0;
}

.stickyContent {
  position: sticky;
  top: 160px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  /* Increase the height if needed */
  /* Adjust this if you want more or less height */
  margin: 0 auto;
}

.text {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.2);
}

.placeholder {
  position: absolute;
  opacity: 0.3;
}

.revealedWord {
  color: var(--Base-0);
}
.noStyle .revealedWord {
  color: inherit;
}
.wordWrapper {
  position: relative;
  margin: 0 4px;
  font-size: 40px;
  line-height: 133.33%;
  font-weight: 600;
}
.noStyle {
  font-size: inherit;
}
@media only screen and (max-width: 991px) {
  .wordWrapper {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .wordWrapper {
    font-size: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .wordWrapper {
    font-size: 20px;
  }
  .stickyContent {
    top: 120px;
  }
}
