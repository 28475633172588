.heading {
  font-style: normal;
  font-weight: 700;
  line-height: 110%;

  color: var(--Base-0);
  font-size: 96px;
}
.base950 {
  color: var(--Base-950);
}
.h1 {
  font-size: 96px;
  line-height: 100%;
}
.h2 {
  font-size: 60px;
}
.h3 {
  font-size: 48px;
}
.xs {
  font-size: 12px;
}
.sm {
  font-size: 14px;
}
.md {
  font-size: 14px;
}
.base {
  font-size: 16px;
}
.lg {
  font-size: 18px;
}
.xl {
  font-size: 20px;
}
.xl2 {
  font-size: 24px;
}
.xl3 {
  font-size: 30px;
}
.xl4 {
  font-size: 36px;
}

.xl6 {
  font-size: 60px;
}

.base0 {
  color: var(--Base-0);
}

.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.bold {
  font-weight: 700;
}
.semiBold {
  font-weight: 500;
}
.font600 {
  font-weight: 600;
}
.regular {
  font-weight: 400;
}
@media only screen and (max-width: 1199px) {
  .h1 {
    font-size: 72px;
  }
  .h2 {
    font-size: 48px;
  }
  .xl6 {
    font-size: 48px;
  }
  .h3 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .h1 {
    font-size: 60px;
  }
  .h2 {
    font-size: 36px;
  }
  .xl6 {
    font-size: 40px;
  }
  .h3 {
    font-size: 32px;
  }
  .xl3 {
    font-size: 26px;
  }
  .xl2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 520px) {
  .h1 {
    font-size: 30px;
    line-height: 110%;
  }
  .xl6 {
    font-size: 36px;
  }
  .h2 {
    font-size: 30px;
  }
  .h3 {
    font-size: 24px;
  }
  .lg {
    font-size: 16px;
  }
}
